<script>
import { required, email } from "vuelidate/lib/validators";
import i18n from "../../../i18n";

export default {
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      languages: [
        {
          flag: require("@/assets/images/flags/russia.jpg"),
          language: "ru",
          title: "Русский",
        },
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
      ],
      current_language: "ru",
    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
    currentFlagPath: function(){
      let result = '';
      //i18n.locale
      for(let key in this.languages){
        if(i18n.locale == this.languages[key].language){
          result = this.languages[key].flag;
        }
      }
      return result;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    email: { required, email },
    password: { required }
  },
  methods: {
    setLanguage(locale) {
      let reloadPage = false;
      if(i18n.locale != locale){
        reloadPage = true;
      }
      i18n.locale = locale;
      this.current_language = i18n.locale;
      localStorage.setItem("locale", locale);
      if(reloadPage){
        location.reload();
      }
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let email = this.email;
        let password = this.password;

        this.$store
            .dispatch("login", { email, password })
            .then(() => {
              // this.$store.dispatch("stopPreloader");
              this.$router.push("/");
            })
            .catch(err => {
              this.$store.dispatch('notificationError', err.message);
            });
      }
    }
  },
  mounted(){
    let locale = localStorage.getItem("locale");
    if(locale){
      this.setLanguage(locale);
    }
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <h4 class="font-size-18 mt-4">{{$t('views.login.welcome')}}</h4>
                        <p class="text-muted">{{$t('views.login.signInCrm')}}</p>
                      </div>

                      <b-alert
                        :variant="notification.type"
                        class="mt-3"
                        v-if="notification.message"
                        show
                        dismissible
                      >{{notification.message}}</b-alert>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-mail-line auti-custom-input-icon"></i>
                            <label for="email">Email</label>
                            <input
                              type="email"
                              v-model="email"
                              class="form-control"
                              id="email"
                              :placeholder="$t('views.login.insertEmail')"
                              :class="{ 'is-invalid': submitted && $v.email.$error }"
                            />
                            <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                              <span v-if="!$v.email.required">{{$t('views.login.emailReq')}}</span>
                              <span v-if="!$v.email.email">{{$t('views.login.notEmail')}}</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">{{$t('views.login.password')}}</label>
                            <input
                              v-model="password"
                              type="password"
                              class="form-control"
                              id="userpassword"
                              :placeholder="$t('views.login.insertPass')"
                              :class="{ 'is-invalid': submitted && $v.password.$error }"
                            />
                            <div
                              v-if="submitted && !$v.password.required"
                              class="invalid-feedback"
                            >{{$t('views.login.passReq')}}</div>
                          </div>

                          <div class="mt-4 text-center">
                            <button
                              class="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >{{$t('views.login.signIn')}}</button>
                          </div>

                          <div class="mt-4 text-center">
                            <b-dropdown variant="white" right toggle-class="header-item">
                              <template v-slot:button-content>
                                <img
                                  class
                                  :src="currentFlagPath"
                                  alt="Header Language"
                                  height="16"
                                />
                              </template>
                              <b-dropdown-item
                                class="notify-item"
                                v-for="(entry, i) in languages"
                                :key="`Lang${i}`"
                                :value="entry"
                                @click="setLanguage(entry.language)"
                                :link-class="{ active: entry.language === current_language }"
                              >
                                <img
                                  :src="`${entry.flag}`"
                                  alt="user-image"
                                  class="mr-1"
                                  height="12"
                                />
                                <span class="align-middle">{{ entry.title }}</span>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Забыли пароль?
                            </router-link>
                          </div> -->
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
